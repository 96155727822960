import {Component, Input, OnInit} from "@angular/core"
import {environment} from "environments/environment"

interface Category {
  label: string;
  value: string;
}

interface Tutorial {
  title: string;
  category: string;
  image: string;
  link: string;
  isVisible: boolean;
}

@Component({
  selector: "celic-tutorials",
  templateUrl: "./celic-tutorials.component.html",
  styleUrls: ["./celic-tutorials.component.css"],
})
export class CelicTutorialsComponent {
  @Input() guestRoles:string[] = [];

  public categories:Category[] = [
    {
      label: "Admisiones y Registro",
      value: "register",
    },
    {
      label: "Observador Virtual",
      value: "virtual-observer",
    },
    {
      label: "Comunicación Institucional",
      value: "communication",
    },
    {
      label: "Bienestar Institucional",
      value: "wellness",
    },
    {
      label: "Integración Plataformas",
      value: "platforms",
    },
  ]

  public tutorials: Tutorial[] = [
    {
      title: "Registro Acudiente",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("registro-acudiente", "jpg"),
      link: "https://drive.google.com/file/d/1xerBcg4BdSwnVawQ9q_St2lq7T3Up1UB/view?usp=drive_link",
      isVisible: true
    },
    {
      title: "Registro Docente",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("registro-docente", "jpg"),
      link: "https://drive.google.com/file/d/16vmNq5FXMBbq8XjnsHL5RBYhdWErHf-v/view?usp=drive_link",
      isVisible: true
    },
    {
      title: "Registro Estudiante",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("registro-estudiante", "jpg"),
      link: "https://drive.google.com/file/d/1jaeI3FvO3n4UrW21OoC6pr8wizimNt1r/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'ACUDIENTE'])
    },
    {
      title: "Inscripción Estudiantes",
      category: "register",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("inscripcion-estudiante", "jpg"),
      link: "https://drive.google.com/file/d/1ih_wys0Q7avcx54Z7qBxJyq13aa6MpTy/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'ESTUDIANTE'])
    },
    {
      title: "Docentes",
      category: "virtual-observer",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("observaciones-docente", "jpg"),
      link: "https://drive.google.com/file/d/1-7vAzL-bxRQ1wzrJYkjgDF04BCVwv6Ow/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'DOCENTE'])
    },
    {
      title: "Acudientes",
      category: "virtual-observer",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("observaciones-acudiente", "jpg"),
      link: "https://drive.google.com/file/d/1kvvaKVBqr6vOy_HPIMKkfprHG6v4F3G0/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'ACUDIENTE'])
    },
    {
      title: "Estudiantes",
      category: "virtual-observer",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("observaciones-estudiante", "jpg"),
      link: "https://drive.google.com/file/d/1bqsL6gbkeLzL8iRTQ4KKWv0LLGmB6Y8z/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'ESTUDIANTE'])

    },
    {
      title: "Nuevo Correo",
      category: "communication",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("ci-nuevo-correo", "jpg"),
      link: "https://drive.google.com/file/d/1iewnhh3yQmwwKkUPx64PgnWCmhLCEGCj/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'COMUNICACIONES', 'COMUNICACIONES DIRECCIÓN GENERAL'])
    },
    {
      title: "Nueva Noticia",
      category: "wellness",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("bi-nueva-noticia", "jpg"),
      link: "https://drive.google.com/file/d/1YwzrVW4JYlnvnw2nuks8H3weUDrSe8tn/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'BIENESTAR', 'COMUNICACIONES', 'COMUNICACIONES DIRECCIÓN GENERAL'])
    },
    {
      title: "Nuevo Evento",
      category: "wellness",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("bi-nuevo-evento", "jpg"),
      link: "https://drive.google.com/file/d/1BZN9BcvD2j3uOJWT-LoV5hVpieGaW8Nf/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'BIENESTAR', 'COMUNICACIONES', 'COMUNICACIONES DIRECCIÓN GENERAL'])
    },
    {
      title: "Aprobación",
      category: "wellness",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("bi-aprobacion", "jpg"),
      link: "https://drive.google.com/file/d/1XIz37v8UaB63yNaG6b8zTQG3HTJNqfq1/view?usp=drive_link",
      isVisible: this.hasRoles(['ADMIN', 'BIENESTAR', 'COMUNICACIONES DIRECCIÓN GENERAL'])
    },
    {
      title: "Plataformas",
      category: "platforms",
      image: environment.TUTORIAL_IMAGE_DYNAMIC("ip-plataformas", "jpg"),
      link: "https://drive.google.com/file/d/1eIN0qqBYMbZqNPq4ZnjwOoG_imFXYtxR/view?usp=drive_link",
      isVisible: true
    }
  ]

  getTutorialsByCategory(category: string):boolean {
    const result = this.tutorials.filter((tutorial) => tutorial.category === category && tutorial.isVisible);
    return result.length > 0
  }

  private hasRoles(roles: string[]): boolean {
    return roles.some((role) => this.guestRoles.includes(role));
  }
}
