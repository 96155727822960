import {Component, OnInit} from "@angular/core"
import { UsersService } from "app/models/usersInfo/users.service"
import { error } from "console";
import {environment} from "environments/environment"

@Component({
  selector: "Tutorial",
  templateUrl: "./tutorial.component.html",
})
export class TutorialComponent implements OnInit {
  public APP_NAME: string = environment.APP_NAME

  constructor(private  readonly _userService: UsersService,){}


  public roleNames:string[] = [];

  ngOnInit(): void {
    const currentUser = localStorage.getItem('currentUser');
    if(currentUser){
      this._userService.get(Number(currentUser)).subscribe({
        next:(resp:any) => {
          this.roleNames = resp.roleMappings.map(rm => rm.role.name.toUpperCase());
          console.log(this.roleNames);
        },
        error:(err) => {
          console.log(err);
        }
      })
    }
  }
}
